export const DEVICE_TYPE_SITE = 1;
export const DEVICE_TYPE_ANDROID_V2 = 8;
export const DEVICE_TYPE_ANDROID_V3 = 10;
export const DEVICE_TYPE_IOS_V2 = 7;
export const DEVICE_TYPE_IOS_V3 = 9;

export const deviceTypeIcons: Record<number, string> = {
  [DEVICE_TYPE_SITE]: 'language',
  [DEVICE_TYPE_ANDROID_V2]: 'android',
  [DEVICE_TYPE_ANDROID_V3]: 'android',
  [DEVICE_TYPE_IOS_V2]: 'apple',
  [DEVICE_TYPE_IOS_V3]: 'apple',
}

export const deviceTypeColor: Record<number, string> = {
  [DEVICE_TYPE_SITE]: 'black',
  [DEVICE_TYPE_ANDROID_V2]: 'black',
  [DEVICE_TYPE_ANDROID_V3]: 'warning',
  [DEVICE_TYPE_IOS_V2]: 'black',
  [DEVICE_TYPE_IOS_V3]: 'warning',
}